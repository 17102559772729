<template>
  <b-row>
    <b-col>
      <b-card>
        <b-row>
          <!-- DETAILS -->
          <b-col cols="12" md="6" lg="3">
            <b-row>
              <b-col cols="12">
                <b-card-title> Detalls </b-card-title>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Confirmat</h6>
                <div v-if="editMode" class="d-flex">
                  <b-form-checkbox v-model="confirmedInput" switch inline />
                </div>
                <template v-else>
                  <small v-if="confirmed">
                    <b-badge variant="success">Confirmat</b-badge>
                    <span class="ml-50">{{ confirmedAt }}</span>
                  </small>
                  <small v-else>
                    <b-badge variant="danger">No confirmat</b-badge>
                  </small>
                </template>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Localitzador</h6>
                <div v-if="editMode" class="d-flex">
                  <b-form-input v-model="providerLocalizatorInput" trim />
                </div>
                <b-card-text v-else>
                  {{ providerLocalizator }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Data de recollida</h6>
                <div v-if="editMode" class="d-flex">
                  <b-form-datepicker
                    v-model="pickupDateInput"
                    :locale="$i18n.locale"
                    :start-weekday="1"
                    :show-decade-nav="true"
                    :date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    }"
                    hide-header
                    class="w-full"
                    label-no-date-selected=""
                    tabindex="1"
                  />
                  <b-form-timepicker
                    v-model="pickupTimeInput"
                    label-no-time-selected=""
                    locale="es"
                    minutes-step="10"
                    hide-header
                    no-close-button
                    tabindex="2"
                  />
                </div>
                <b-card-text v-else>
                  {{ pickupDate }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Nº de vol</h6>
                <b-form-input
                  v-if="editMode"
                  v-model="flightNumberInput"
                  maxlength="10"
                  trim
                />
                <b-card-text v-else>
                  {{ flightNumber }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Origen</h6>
                <v-select
                  v-if="editMode"
                  v-model="pickupLocationInput"
                  :options="locationOptions"
                  :reduce="(option) => option.value"
                  :clearable="false"
                />
                <template v-else>
                  <b-card-text v-if="pickupLocationName" class="mb-0">
                    {{ pickupLocationName }}
                  </b-card-text>
                  <b-card-text v-if="pickupLocationAddress" class="mb-0">
                    {{ pickupLocationAddress }}
                  </b-card-text>
                  <b-card-text v-if="pickupGps">
                    {{ pickupGps }}
                  </b-card-text>
                </template>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Destí</h6>
                <v-select
                  v-if="editMode"
                  v-model="dropoffLocationInput"
                  :options="locationOptions"
                  :reduce="(option) => option.value"
                  :clearable="false"
                />
                <template v-else>
                  <b-card-text v-if="dropoffLocationName" class="mb-0">
                    {{ dropoffLocationName }}
                  </b-card-text>
                  <b-card-text v-if="dropoffLocationAddress" class="mb-0">
                    {{ dropoffLocationAddress }}
                  </b-card-text>
                  <b-card-text v-if="dropoffGps">
                    {{ dropoffGps }}
                  </b-card-text>
                </template>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Passatgers</h6>
                <b-form-spinbutton
                  v-if="editMode"
                  v-model="passengersInput"
                  min="1"
                  max="12"
                  step="1"
                />
                <b-card-text v-else>
                  {{ passengers }}
                </b-card-text>
              </b-col>
            </b-row>
          </b-col>

          <!-- EXTRAS -->
          <b-col cols="12" md="6" lg="3" class="mt-3 mt-md-0">
            <b-row>
              <b-col cols="12">
                <b-card-title> Extres </b-card-title>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Maxi Cosi</h6>
                <b-form-spinbutton
                  v-if="editMode"
                  v-model="maxiCosisInput"
                  min="0"
                  max="10"
                />
                <b-card-text v-else>
                  {{ maxiCosis }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Seient per a nadons</h6>
                <b-form-spinbutton
                  v-if="editMode"
                  v-model="babySeatsInput"
                  min="0"
                  max="10"
                />
                <b-card-text v-else>
                  {{ babySeats }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Seient elevador</h6>
                <b-form-spinbutton
                  v-if="editMode"
                  v-model="boosterSeatsInput"
                  min="0"
                  max="10"
                />
                <b-card-text v-else>
                  {{ boosterSeats }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Carret per a nadons</h6>
                <b-form-spinbutton
                  v-if="editMode"
                  v-model="babyTrolleysInput"
                  min="0"
                  max="10"
                />
                <b-card-text v-else>
                  {{ babyTrolleys }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Bicicleta</h6>
                <b-form-spinbutton
                  v-if="editMode"
                  v-model="bicyclesInput"
                  min="0"
                  max="10"
                />
                <b-card-text v-else>
                  {{ bicycles }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Set de pals de golf</h6>
                <b-form-spinbutton
                  v-if="editMode"
                  v-model="golfSetsInput"
                  min="0"
                  max="10"
                />
                <b-card-text v-else>
                  {{ golfSets }}
                </b-card-text>
              </b-col>
            </b-row>
          </b-col>

          <!-- CLIENT -->
          <b-col cols="12" md="6" lg="3" class="mt-3 mt-lg-0">
            <b-row>
              <b-col cols="12">
                <b-card-title> Client </b-card-title>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Nom</h6>
                <div v-if="editMode" class="d-flex">
                  <b-form-input v-model="clientFirstNameInput" trim />
                  <b-form-input v-model="clientLastNameInput" trim />
                </div>
                <b-card-text v-else>
                  {{ clientFullName }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Email</h6>
                <b-form-input v-if="editMode" v-model="clientEmailInput" trim />
                <b-card-text v-else>
                  {{ clientEmail }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Telèfon</h6>
                <b-form-input v-if="editMode" v-model="clientPhoneInput" trim />
                <b-card-text v-else>
                  {{ clientPhone }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Tipus document</h6>
                <v-select
                  v-if="editMode"
                  v-model="clientIdTypeInput"
                  :options="idTypeOptions"
                  :reduce="(option) => option.value"
                  class="w-full"
                  :clearable="false"
                />
                <b-card-text v-else>
                  {{ clientIdType }}
                </b-card-text>
              </b-col>
              <b-col cols="12">
                <h6 class="mb-25">Nº document</h6>
                <b-form-input
                  v-if="editMode"
                  v-model="clientIdNumberInput"
                  trim
                />
                <b-card-text v-else>
                  {{ clientIdNumber }}
                </b-card-text>
              </b-col>
            </b-row>
          </b-col>

          <!-- PRICES -->
          <b-col cols="12" md="6" lg="3" class="mt-3 mt-lg-0">
            <b-row>
              <b-col cols="12">
                <b-card-title> Preus </b-card-title>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Transfer</h6>
                <b-input-group v-if="editMode" append="€">
                  <b-form-input
                    v-model="basePriceInput"
                    type="number"
                    class="text-right"
                    @input="calculateTotalAndVat"
                  />
                </b-input-group>
                <b-card-text v-else>
                  {{ basePrice }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">Extres</h6>
                <b-input-group v-if="editMode" append="€">
                  <b-form-input
                    v-model="extrasPriceInput"
                    type="number"
                    class="text-right"
                    @input="calculateTotalAndVat"
                  />
                </b-input-group>
                <b-card-text v-else>
                  {{ extrasPrice }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">PVP</h6>
                <b-input-group v-if="editMode" append="€">
                  <b-form-input
                    v-model="pvpPriceInput"
                    class="text-right"
                    readonly
                  />
                </b-input-group>
                <b-card-text v-else>
                  {{ pvpPrice }}
                </b-card-text>
              </b-col>
              <b-col cols="12" class="mb-1">
                <h6 class="mb-25">I.V.A.</h6>
                <b-input-group v-if="editMode" append="€">
                  <b-input-group-prepend>
                    <b-form-select
                      v-model="vatTypeInput"
                      :options="vatTypeOptions"
                      @change="calculateTotalAndVat"
                    />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="vatPriceInput"
                    class="text-right"
                    readonly
                  />
                </b-input-group>
                <b-card-text v-else>
                  {{ vatPrice }}
                </b-card-text>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <!-- BUTTONS -->
        <b-row class="d-flex justify-content-between justify-content-sm-end">
          <b-col v-if="editMode" cols="6" sm="auto">
            <b-button block variant="light" @click="editMode = false">
              Cancel·la
            </b-button>
          </b-col>
          <b-col v-if="editMode" cols="6" sm="auto">
            <b-button block variant="primary" @click="onSaveButtonClicked">
              Guarda
            </b-button>
          </b-col>
          <b-col v-if="!editMode" cols="6" sm="auto">
            <b-button block variant="secondary" @click="copyTransferInfo">
              Copia resum al porta-retalls
            </b-button>
          </b-col>
          <b-col v-if="!editMode" cols="6" sm="auto">
            <b-button block variant="primary" @click="onEditButtonClicked">
              Edita
            </b-button>
          </b-col>
          <b-col v-if="!editMode" cols="6" sm="auto">
            <b-button block variant="primary" @click="onConfirmButtonClicked">
              {{ confirmed ? "Desconfirma" : "Confirma" }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col cols="12">
      <b-card title="Danger zone">
        <b-row>
          <b-col cols="12" class="mb-1">
            <b-button size="xl" variant="danger" @click="confirmDeleteTransfer">
              Elimina el transfer
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BButton,
  BCardTitle,
  BFormDatepicker,
  BFormTimepicker,
  BFormSpinbutton,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BBadge,
  BFormCheckbox,
} from "bootstrap-vue";
import {
  getFullAddress,
  getIdTypeName,
  getPlaceTypeName,
  notifyError,
  notifySuccess,
} from "@/utils/methods";
import {
  formatCurrency,
  formatDateObjectToDatabaseDateTime,
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import vSelect from "vue-select";
import { idTypeOptions } from "@/utils/select-options";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    BButton,
    BCardTitle,
    BFormDatepicker,
    BFormTimepicker,
    BFormSpinbutton,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    vSelect,
    BBadge,
    BFormCheckbox,
  },
  data() {
    return {
      editMode: false,
      confirmedInput: false,
      providerLocalizatorInput: null,
      pickupDateInput: null,
      pickupTimeInput: null,
      flightNumberInput: null,
      pickupLocationInput: null,
      dropoffLocationInput: null,
      passengersInput: null,
      maxiCosisInput: null,
      babySeatsInput: null,
      boosterSeatsInput: null,
      babyTrolleysInput: null,
      bicyclesInput: null,
      golfSetsInput: null,
      clientFirstNameInput: null,
      clientLastNameInput: null,
      clientEmailInput: null,
      clientPhoneInput: null,
      clientIdTypeInput: null,
      clientIdNumberInput: null,
      basePriceInput: null,
      extrasPriceInput: null,
      pvpPriceInput: null,
      vatTypeInput: 21,
      vatTypeOptions: [
        { value: 0, text: "0 %" },
        { value: 10, text: "10 %" },
        { value: 21, text: "21 %" },
      ],
      vatPriceInput: null,
      transferInfoText: "prova",
      idTypeOptions,
    };
  },
  computed: {
    transfer() {
      return this.$store.getters["transfers/transfer"];
    },
    clientFullName() {
      const name = [];
      if (this.transfer?.clientFirstName)
        name.push(this.transfer.clientFirstName);
      if (this.transfer?.clientLastName)
        name.push(this.transfer.clientLastName);
      return name.length ? name.join(" ") : this.$t("No definit");
    },
    clientEmail() {
      return this.transfer?.clientEmail || this.$t("No definit");
    },
    clientPhone() {
      return this.transfer?.clientPhone || this.$t("No definit");
    },
    clientIdType() {
      return this.transfer?.clientIdType
        ? getIdTypeName(this.transfer.clientIdType, this.$i18n.locale)
        : this.$t("No definit");
    },
    clientIdNumber() {
      return this.transfer?.clientIdNumber || this.$t("No definit");
    },
    confirmed() {
      return this.transfer?.confirmed || false;
    },
    confirmedAt() {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return (
        formatDateStringToDate(
          this.transfer?.confirmedAt,
          this.$i18n.locale,
          formatting
        ) || null
      );
    },
    providerLocalizator() {
      return this.transfer?.providerLocalizator || this.$t("No definit");
    },
    pickupDate() {
      const transferDate = this.transfer?.pickupDate
        ? this.transfer.pickupDate.split("T")[0]
        : null;

      if (!transferDate) return this.$t("No definit");

      const transferTime = this.transfer?.pickupTime || null;
      const transferDateString = transferTime
        ? `${transferDate}T${transferTime}`
        : transferDate;

      const formatting = {
        day: "numeric",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };

      return (
        formatDateStringToDate(
          transferDateString,
          this.$i18n.locale,
          formatting
        ) || this.$t("No definit")
      );
    },
    flightNumber() {
      return this.transfer?.flightNumber || this.$t("No definit");
    },
    passengers() {
      return this.transfer?.passengers || this.$t("No definit");
    },
    pickupLocation() {
      return this.transfer?.pickupLocation || null;
    },
    pickupLocationName() {
      return this.pickupLocation?.name || null;
    },
    pickupLocationAddress() {
      return (
        getFullAddress(
          this.pickupLocation,
          this.$i18n.locale,
          true,
          true,
          false
        ) || null
      );
    },
    pickupGps() {
      if (!this.pickupLocation?.latitude || !this.pickupLocation?.longitude)
        return null;
      return `GPS: ${this.pickupLocation.latitude}, ${this.pickupLocation.longitude}`;
    },
    dropoffLocation() {
      return this.transfer?.dropoffLocation || null;
    },
    dropoffLocationName() {
      return this.dropoffLocation?.name || null;
    },
    dropoffLocationAddress() {
      return (
        getFullAddress(
          this.dropoffLocation,
          this.$i18n.locale,
          true,
          true,
          false
        ) || null
      );
    },
    dropoffGps() {
      if (!this.dropoffLocation?.latitude || !this.dropoffLocation?.longitude)
        return null;
      return `GPS: ${this.dropoffLocation.latitude}, ${this.dropoffLocation.longitude}`;
    },
    maxiCosis() {
      return this.transfer?.maxiCosis || 0;
    },
    babySeats() {
      return this.transfer?.babySeats || 0;
    },
    boosterSeats() {
      return this.transfer?.boosterSeats || 0;
    },
    babyTrolleys() {
      return this.transfer?.babyTrolleys || 0;
    },
    bicycles() {
      return this.transfer?.bicycles || 0;
    },
    golfSets() {
      return this.transfer?.golfSets || 0;
    },
    basePrice() {
      if (!this.transfer?.basePrice) return this.$t("No definit");
      return formatCurrency(this.transfer.basePrice);
    },
    extrasPrice() {
      if (!this.transfer?.extrasPrice) return this.$t("No definit");
      return formatCurrency(this.transfer.extrasPrice);
    },
    pvpPrice() {
      if (!this.transfer?.pvpPrice) return this.$t("No definit");
      return formatCurrency(this.transfer.pvpPrice);
    },
    vatPrice() {
      if (!this.transfer?.vatPrice) return this.$t("No definit");
      return formatCurrency(this.transfer.vatPrice);
    },
    loadingPlaces() {
      return this.$store.getters["places/loading"];
    },
    places() {
      return this.$store.getters["places/places"];
    },
    locationOptions() {
      const options = [];
      if (this.transfer?.booking?.accommodation?.location) {
        options.push({
          value: this.transfer.booking.accommodation.location["@id"],
          label: `${this.$t("Allotjament")} - ${
            this.transfer.booking.accommodation.name
          }`,
        });
      }
      this.places.forEach((place) => {
        if (!place.location) return;
        options.push({
          value: place.location["@id"],
          label: `${getPlaceTypeName(place.type)} - ${place.name}`,
        });
      });
      return options;
    },
  },
  created() {
    this.fetchTransfer();
    if (this.places.length === 0 && !this.loadingPlaces) this.fetchPlaces();
  },
  methods: {
    fetchTransfer() {
      this.$store.dispatch("app/setLoading", true);
      const { transferUuid } = this.$route.params;

      this.$store
        .dispatch("transfers/fetchTransfer", transferUuid)
        .then(() => this.initTransferInfoText())
        .catch(() =>
          notifyError(
            this.$t("errors.fetchTransfer.title"),
            this.$t("errors.fetchTransfer.description")
          )
        )
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    fetchPlaces() {
      this.$store
        .dispatch("places/fetchPlaces", { pagination: false, sort: "asc" })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchPlaces.title"),
            this.$t("errors.fetchPlaces.description")
          )
        );
    },
    confirmDeleteTransfer() {
      this.$swal({
        title: "Eliminar transfer",
        text: "Estàs a punt d'eliminar aquest transfer, estàs segur/a?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Elimina",
        cancelButtonText: "Cancel·la",
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) this.deleteTransfer();
      });
    },
    deleteTransfer() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("transfers/deleteTransfer", this.transfer.uuid)
        .then(() => {
          this.$router.push({ name: "foravila-transfers-list" });
          notifySuccess(
            "Transfer eliminat",
            "El transfer ha estat eliminat correctament."
          );
        })
        .catch(() => {
          notifyError(
            "Transfer no eliminat",
            "Hi ha hagut un error al eliminar el transfer."
          );
          // TODO: log error in Sentry
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    initInputs() {
      this.confirmedInput = this.transfer.confirmed || false;
      this.providerLocalizatorInput = this.transfer.providerLocalizator || null;
      this.pickupDateInput = this.transfer.pickupDate;
      this.pickupTimeInput = this.transfer.pickupTime;
      this.flightNumberInput = this.transfer.flightNumber;
      this.pickupLocationInput = this.transfer.pickupLocation["@id"];
      this.dropoffLocationInput = this.transfer.dropoffLocation["@id"];
      this.passengersInput = this.transfer.passengers;
      this.maxiCosisInput = this.transfer.maxiCosis;
      this.babySeatsInput = this.transfer.babySeats;
      this.boosterSeatsInput = this.transfer.boosterSeats;
      this.babyTrolleysInput = this.transfer.babyTrolleys;
      this.bicyclesInput = this.transfer.bicycles;
      this.golfSetsInput = this.transfer.golfSets;
      this.clientFirstNameInput = this.transfer.clientFirstName;
      this.clientLastNameInput = this.transfer.clientLastName;
      this.clientEmailInput = this.transfer.clientEmail;
      this.clientPhoneInput = this.transfer.clientPhone;
      this.clientIdTypeInput = this.transfer.clientIdType;
      this.clientIdNumberInput = this.transfer.clientIdNumber;
      this.basePriceInput = this.transfer.basePrice / 100;
      this.extrasPriceInput = this.transfer.extrasPrice / 100;
      this.pvpPriceInput = this.transfer.pvpPrice / 100;
      this.vatPriceInput = this.transfer.vatPrice / 100;
    },
    onEditButtonClicked() {
      this.initInputs();
      this.editMode = true;
    },
    onConfirmButtonClicked() {
      this.$store.dispatch("app/setLoading", true);

      const newConfirmed = !this.confirmed;
      const newConfirmedAt = newConfirmed
        ? formatDateObjectToDatabaseDateTime(new Date())
        : null;

      this.$store
        .dispatch("transfers/updateTransfer", {
          uuid: this.transfer.uuid,
          confirmed: newConfirmed,
          confirmedAt: newConfirmedAt,
        })
        .catch(() => {
          notifyError(
            "Transfer no actualitzat",
            "El transfer no s'ha pogut actualitzar correctament, si us plau, intenta-ho de nou i contacta amb en Pedro si el problema persisteix"
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    onSaveButtonClicked() {
      this.$store.dispatch("app/setLoading", true);
      this.$store
        .dispatch("transfers/updateTransfer", {
          uuid: this.transfer.uuid,
          confirmed: this.confirmedInput || false,
          confirmedAt: this.confirmedInput
            ? formatDateObjectToDatabaseDateTime(new Date())
            : null,
          providerLocalizator: this.providerLocalizatorInput,
          pickupDate: formatDateStringToDatabaseDate(this.pickupDateInput),
          pickupTime: this.pickupTimeInput
            ? this.pickupTimeInput.substring(0, 5)
            : null,
          flightNumber: this.flightNumberInput,
          pickupLocation: this.pickupLocationInput,
          dropoffLocation: this.dropoffLocationInput,
          passengers: parseInt(this.passengersInput),
          maxiCosis: parseInt(this.maxiCosisInput),
          babySeats: parseInt(this.babySeatsInput),
          boosterSeats: parseInt(this.boosterSeatsInput),
          babyTrolleys: parseInt(this.babyTrolleysInput),
          bicycles: parseInt(this.bicyclesInput),
          golfSets: parseInt(this.golfSetsInput),
          clientFirstName: this.clientFirstNameInput,
          clientLastName: this.clientLastNameInput,
          clientEmail: this.clientEmailInput,
          clientPhone: this.clientPhoneInput,
          clientIdType: this.clientIdTypeInput,
          clientIdNumber: this.clientIdNumberInput,
          basePrice: Math.round(this.basePriceInput * 100),
          extrasPrice: Math.round(this.extrasPriceInput * 100),
          pvpPrice: Math.round(this.pvpPriceInput * 100),
          vatPrice: Math.round(this.vatPriceInput * 100),
        })
        .then(() => {
          this.editMode = false;
        })
        .catch(() => {
          notifyError(
            "Transfer no editat",
            "El transfer no s'ha pogut editar correctament, si us plau, revisa les dades introduïdes"
          );
        })
        .finally(() => this.$store.dispatch("app/setLoading", false));
    },
    calculateTotalAndVat() {
      if (!this.basePriceInput) this.basePriceInput = 0;
      if (!this.extrasPriceInput) this.extrasPriceInput = 0;

      this.pvpPriceInput =
        parseFloat(this.basePriceInput) + parseFloat(this.extrasPriceInput);

      const calculatedVatPrice =
        this.pvpPriceInput - this.pvpPriceInput / (1 + this.vatTypeInput / 100);
      this.vatPriceInput = Math.round(calculatedVatPrice * 100) / 100;
    },
    initTransferInfoText() {
      const text = [];
      if (this.pickupDate) text.push(`Data de recollida: ${this.pickupDate}`);
      if (this.clientFullName) text.push(`Nom: ${this.clientFullName}`);
      if (this.clientPhone) text.push(`Telèfon: ${this.clientPhone}`);
      text.push(`Origen: ${this.pickupLocationName || ""}`);
      if (this.pickupLocationAddress) text.push(this.pickupLocationAddress);
      if (this.pickupGps) text.push(`${this.pickupGps}`);
      text.push(`Destí: ${this.dropoffLocationName || ""}`);
      if (this.dropoffLocationAddress) text.push(this.dropoffLocationAddress);
      if (this.dropoffGps) text.push(`${this.dropoffGps}`);
      if (this.flightNumber) text.push(`Nº de vol: ${this.flightNumber}`);
      if (this.passengers) text.push(`Passatgers: ${this.passengers}`);
      if (this.maxiCosis) text.push(`Maxi Cosi: ${this.maxiCosis}`);
      if (this.babySeats) text.push(`Seient per a nadons: ${this.babySeats}`);
      if (this.boosterSeats) text.push(`Seient elevador: ${this.boosterSeats}`);
      if (this.babyTrolleys)
        text.push(`Carret per a nadons: ${this.babyTrolleys}`);
      if (this.bicycles) text.push(`Bicicleta: ${this.bicycles}`);
      if (this.golfSets) text.push(`Set de pals de golf: ${this.golfSets}`);
      if (this.pvpPrice) text.push(`PVP: ${this.pvpPrice}`);
      this.transferInfoText = text.length ? text.join("\n") : "";
    },
    copyTransferInfo() {
      this.$copyText(this.transferInfoText);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
